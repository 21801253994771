import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { useAxios } from "./AxiosProvider";
import { useHistory } from "react-router-dom";

export default function useAuth() {
	const axios = useAxios();
	const queryClient = useQueryClient();
	const history = useHistory();
	const [user, setUser] = useState(null);
	async function authReqest(info) {
		const { data } = await axios.post("/administrator/auth", info);
		return data;
	}

	async function authQueryErrorHandler() {
		return checkAuthRequest();
	}

	async function checkAuthRequest() {
		const { data } = await axios.post("/administrator/verify");
		return data;
	}

	const { mutate: authenticate, isLoading: authenticating } = useMutation(
		authReqest,
		{
			onSuccess: (data) => {
				const { token } = data;
				localStorage.setItem("somity_administration_token", token);
				queryClient.setQueryData("auth", { ...data, isAuthenticated: true });
				setUser((p) => ({ user: data?.user }));
				queryClient.invalidateQueries("auth");
			},
			onError: (err) => {
				message.error(err?.response.data.message);
			},
		}
	);

	function logoutAction() {
		localStorage.removeItem("somity_administration_token");
		queryClient.setQueryData("auth", { isAuthenticated: false });

		history.push("/");
		window.location.reload();
	}

	return {
		authenticate,
		authenticating,
		logoutAction,
		authQueryErrorHandler,
		user,
	};
}
