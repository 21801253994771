import React, { useState, useEffect } from "react";
import { Layout, Menu, Button } from "antd";
import {
  UserOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  MessageOutlined,
  EnvironmentOutlined,
  NotificationOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import useWindowSize from "../../hook/useWindowsize";
import { Link } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;

const LayoutContainer = ({ children }) => {
  const history = useHistory();
  const windowSize = useWindowSize();
  const [sidbar, setSidbar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  function handleSidebar() {
    if (isMobile) {
      setSidbar((p) => !p);
    }
  }

  useEffect(() => {
    if (windowSize) {
      if (windowSize.width < 600) {
        setIsMobile(true);
        setSidbar(false);
      } else {
        setIsMobile(false);
        setSidbar(true);
      }
    }

  }, [windowSize]);

  return (
    <Layout>
      <Header className='header'>
        <div className='logo'>
          <span style={{ color: "#fff", fontSize: 20 }}>
            Somity Administration
          </span>

          {isMobile && (
            <Button
              onClick={handleSidebar}
              size='small'
              style={{ float: "right", marginTop: 20 }}
              icon={<MenuOutlined />}
            ></Button>
          )}
        </div>
      </Header>
      <Content style={{ marginTop: 60 }}>
        <Layout className='site-layout-background'>
          <Sider
            style={sidbar ? {} : { display: "none" }}
            className='site-layout-background'
            width={200}
          >

              {/* <ul>
                <Link to={'/'}>
                <li  style={{ background:'red',padding:'10px 0px',cursor:'pointer' }}>Dashboard</li>
                </Link>
                <Link to={'/users'}>
                <li style={{ background:'red',padding:'10px 0px',cursor:'pointer' }}>users</li>
                </Link>
              </ul> */}

              {/*  */}

            <Menu  
              mode='inline'
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["1"]}
              style={{ height: "100%" }}
              theme='dark'
            >
              <Menu.Item
                onClick={() => {
                  history.push("/");
                  handleSidebar();
                }}
                key='1'
                icon={<DashboardOutlined />}
              >
                Dashboard
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  history.push("/app-payments");
                  handleSidebar();
                }}
                key='5'
                icon={<DollarCircleOutlined />}
              >
                App Payments
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/sms-payments");
                  handleSidebar();
                }}
                key='6'
                icon={<MessageOutlined />}
              >
                SMS Payments
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/users");
                  handleSidebar();
                }}
                key='7'
                icon={<UserOutlined />}
              >
                Users
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/date-wise-member-count");
                  handleSidebar();
                }}
                key='date-wise-member-count'
                icon={<UserOutlined />}
              >
                Date wise member
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/request-latency");
                  handleSidebar();
                }}
                key='visitor'
                icon={<EnvironmentOutlined />}
              >
                Request Latency
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/visitor");
                  handleSidebar();
                }}
                key='8'
                icon={<EnvironmentOutlined />}
              >
                Visitor
              </Menu.Item>
              
              <Menu.Item
                onClick={() => {
                  history.push("/opinion");
                  handleSidebar();
                }}
                key='9'
                icon={<NotificationOutlined />}
              >
                Opinion
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  history.push("/reports");
                  handleSidebar();
                }}
                key='10'
                icon={<DatabaseOutlined />}
              >
                Reports
              </Menu.Item>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 24px", minHeight: "90vh" }}>
            {children}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>Binary Bunon</Footer>
    </Layout>
  );
};

export default LayoutContainer;
