import React, { Suspense, lazy, useEffect, useState } from "react";
import LayoutContainer from "./screens/layout/Layout";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { useQuery } from "react-query";
import useAuth from "./data/useAuth";
import Login from "./screens/auth/login";
import Statictic from "./screens/statictics/Statictic";
import Modals from "./modals/Modals";
import { useMCtx } from "./lib/MainContext";
import RequestLatency from "./screens/request-latency";
const SMSPaymentRequest = lazy(() =>
  import("./screens/member/SMSPaymentRequest")
);
const AppaymentRequest = lazy(() => import("./screens/member/app-payment"));
const Dashboard = lazy(() => import("./screens/dashboard/Dashboard"));
const UsersScreen = lazy(() => import("./screens/users/Users"));
const Opinion = lazy(() => import("./screens/opinion"));
const Reports = lazy(() => import("./screens/reports/index"));
const MessageLogs = lazy(() => import("./screens/sms-logs/MessageLogs"));
const DateWiseMemberCount = lazy(() => import("./screens/date-wise-member/DateWiseMemberCount"));

function App() {
  const { authQueryErrorHandler } = useAuth();
  const { data } = useQuery("auth", authQueryErrorHandler);
  const [redirectPath, setRedirectPath] = useState("/");

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);


  const { state } = useMCtx();
  const { isModalOpen } = state;

  useEffect(() => {
    if (data !== undefined) {
      if (data?.token) {
        setIsAuthenticated(true);
        setUser(data);
      }
    }
  }, [data]);


  if (!user) {
    return (
      <Switch>
        <Route path='/' exact>
          <Login />
        </Route>
      </Switch>
    );
  }

  return (
    <>
      {isAuthenticated ? (
        <LayoutContainer user={user}>
          
            <Switch>
              <Suspense fallback={<Spin />}>
                <Route path='/' exact>
                  <Dashboard user={user} />
                </Route>
                <Route path='/sms-payments' exact>
                  <SMSPaymentRequest user={user} />
                </Route>
                <Route path='/app-payments' exact>
                  <AppaymentRequest user={user} />
                </Route>
                <Route path='/users'>
                  <UsersScreen user={user} exact />
                </Route>
                <Route path='/visitor'>
                  <Statictic user={user} exact />
                </Route>
                <Route path='/opinion'>
                  <Opinion user={user} exact />
                </Route>

                <Route path='/message-log'>
                  <MessageLogs user={user} exact />
                </Route>

                <Route path='/request-latency'>
                  <RequestLatency user={user} exact />
                </Route>

                <Route path='/reports'>
                  <Reports user={user} exact />
                </Route>
                <Route path='/date-wise-member-count'>
                  <DateWiseMemberCount user={user} exact />
                </Route>

                {/* <Route path='**' exact>
                  <Redirect to={redirectPath} />
                </Route> */}
              </Suspense>
            </Switch>
            
        </LayoutContainer>
      ) : (
        <Switch>
          <Route path='/' exact>
            <Login />
          </Route>
        </Switch>
      )}

    <Modals />
    </>
  );
}

export default App;
